import 'core-js';
import { Logger } from '_common/services';

// custom polyfills
import './polyfills/elementInViewport';
// import './polyfills/charcodePolyfill';

window.global ||= window;

import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import history from 'router/history';
import AppRouter from 'router/index';
import { Provider } from 'react-redux';
import App from 'App/App';
import Intl from 'Intl/Intl';
import { getDoDOCAppConfig } from 'dodoc.config';
import { persistor, store } from '_common/redux';
import { LoadingPage, ErrorBoundary } from '_common/components';
import setupSentry from 'sentry';
import { PersistGate } from 'redux-persist/integration/react';

// Stylesheets
import 'dodoc-design-system/build/css/index.css';
import 'react-virtualized/styles.css';
import 'assets/styles/global.scss';

import 'mathjax/es5/tex-mml-svg';

global.logger = global.logger || Logger;
Object.typedKeys = Object.keys as any;

// MathJax configs
// MathJax.options.enableMenu = false;

getDoDOCAppConfig().then((config) => {
  setupSentry(config);
  if (config.logger?.level) {
    Logger.setLogLevel(config.logger?.level);
  }

  const container = document.getElementById('main');
  const root = createRoot(container!);

  root.render(
    <StrictMode>
      <Provider store={store}>
        <Intl>
          <Suspense fallback={<LoadingPage />}>
            <PersistGate persistor={persistor}>
              <Router history={history}>
                <ErrorBoundary>
                  <App>
                    <AppRouter />
                  </App>
                </ErrorBoundary>
              </Router>
            </PersistGate>
          </Suspense>
        </Intl>
      </Provider>
    </StrictMode>,
  );
});
