import React from 'react';
import { MMLHelper } from '_common/utils/MMLHelper';
import { useEquationsContext } from '../EquationsContext';

interface MathComponentProps {
  mml: string | undefined;
}

const MathComponent: React.FC<MathComponentProps> = ({ mml }) => {
  const { setIsValid } = useEquationsContext();

  if (!mml) {
    return null;
  }

  return (
    <div
      ref={(ref) => {
        if (ref) {
          try {
            ref.innerHTML = '';
            MMLHelper.appendMMLToElement(mml, ref);
          } catch (error) {
            setIsValid(false);
          }
        }
      }}
    ></div>
  );

  return null;
};

export default MathComponent;
