import { changePasswordUpdateForced, setAuthenticated, signedOut } from 'Auth/redux/authSlice';
import { deviceRegistrationRequested, removeUserToken } from 'Auth/redux/localStorageSlice';
import { useEffect } from 'react';
import { navigateToDeviceRegistration } from 'router/history';
import { notify } from '_common/components/ToastSystem';
import { useDispatch, useSelector } from '_common/hooks';
import { closeAllModals } from '_common/modals/ModalsSlice';
import networkEvents from '_common/services/api/events';
import { SessionStorage } from '_common/utils';
import authApi from '_common/services/api/authority';
import { deactivateTenant, setAppLoading, setConnectivityIssuesState } from './redux/appSlice';

const reportRequestException = (error: Request.AxiosError) => {
  logger.captureException(error, {
    fingerprint: ['{{ default }}', error?.request],
    extra: { error },
    tags: {
      url: error.config?.url,
      tenant: error.config?.headers?.['X-DoDOC-Tenant'],
    },
  });
};

const useNetworkEvents = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);

  useEffect(() => {
    networkEvents
      .on('NETWORK_ERROR', () => {
        dispatch(setConnectivityIssuesState(true));
      })
      .on('UNAUTHORIZED', (error) => {
        dispatch(signedOut());
        dispatch(setAppLoading({ isOpen: false }));
        dispatch(closeAllModals());

        const authorizationHeader = error?.config?.headers?.['Authorization'];

        if (authorizationHeader) {
          const token =
            typeof authorizationHeader === 'string' ? authorizationHeader.split(' ')[1] : undefined;
          dispatch(removeUserToken(token));
        }
      })
      .on('BAD_REQUEST', (error) => {
        notify({
          type: 'error',
          title: 'global.error',
          message: 'notifications.defaultError',
        });
        reportRequestException(error);
      })
      .on('FORBIDDEN', (error) => {
        if (!error?.request?.url?.includes('/login')) {
          notify({
            type: 'error',
            title: 'notifications.noPermissions.title',
            message: 'notifications.noPermissions.message',
          });
        }
        reportRequestException(error);
      })
      .on('NOT_FOUND', (error) => {
        notify({
          type: 'error',
          title: 'global.error',
          message: 'notifications.defaultError',
        });
        reportRequestException(error);
      })
      .on('FORCE_PASSWORD_RESET', (error) => {
        notify({
          id: 'forcePasswordReset',
          type: 'error',
          title: 'notifications.passwordOutdated.title',
          message: 'notifications.passwordOutdated.message',
        });
        if (error.response?.data.token) {
          SessionStorage.setToken(error.response.data.token);
        }
        dispatch(changePasswordUpdateForced(true));
      })
      .on('UNKNOWN_DEVICE', (error) => {
        // @ts-expect-error something funky going on
        const { token, device, username, id } = error.toJSON();
        const account = {
          username,
          id,
          token,
          device,
        };
        dispatch(deviceRegistrationRequested(account));
        dispatch(setAppLoading({ isOpen: false }));
        dispatch(setAuthenticated(true));
        navigateToDeviceRegistration();
      })
      .on('TENANT_DEACTIVATED', () => {
        dispatch(authApi.endpoints.signOut.initiate(userId));
        dispatch(deactivateTenant());
      })
      .on('GENERIC_ERROR', (error) => {
        notify({
          type: 'error',
          title: 'notifications.genericError.title',
          message: 'notifications.genericError.message',
        });
        reportRequestException(error);
      });
    return () => {
      networkEvents.removeAllListeners();
    };
  }, []);
};

export default useNetworkEvents;
