import { useEffect } from 'react';
import { useDispatch, useObject } from '_common/hooks';
import { useParams } from 'react-router';
import { InstanceService } from '_common/services';
import { useSelector } from '_common/hooks/redux';
import { FormattedMessage } from 'react-intl';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { navigateTo } from 'router/history';

const FilePage = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const adblock = useSelector((state) => state.app.adblock);

  const { data } = useObject({ object_id: id, object_type: 'file' });

  useEffect(() => {
    if (data) {
      if (data.wopi) {
        const action = data.wopi.actions.find((action: { name: string }) => action.name === 'edit');

        if (action) {
          const wopiAccess = localStorage.getItem(`${userId}-WOPI_ALLOW_ACCESS`);

          if (wopiAccess) {
            const url = `/extensions/wopi/${id}/${action.app}/${action.name}`;
            navigateTo(url);
          } else {
            dispatch(
              openAndUpdateModal({
                modal: 'WOPIRedirectionModal',
                data: { app: action.app, action: action.name, id, self: true },
              }),
            );
          }
        }
      } else {
        new InstanceService().downloadFile(id, 'file').then(({ data: blob }) => {
          const download = adblock || blob.type !== 'application/pdf';
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('data-testid', 'download-file-anchor');
          link.setAttribute('data-downloaded', 'false');
          document.body.appendChild(link);
          if (download) {
            link.setAttribute('download', `${data.name}`);
          }
          link.click();
          if (download) {
            link.setAttribute('data-downloaded', 'true');

            setTimeout(() => {
              window.close();
            }, 200);
          }
        });
      }
    }
  }, [data]);
  return (
    <div>
      <FormattedMessage id="global.loading" />
    </div>
  );
};

export default FilePage;
