import { forwardRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'dodoc-design-system';

import MathML from '../MathML';

import styles from './Preview.module.scss';
import { MMLHelper } from '_common/utils/MMLHelper';
import { Structure, Symbol } from '../ToolbarElements';

type EquationElement = (Structure['elements'][number] | Symbol['elements'][number]) & {
  _equation?: string;
  tex?: string;
};

type PreviewProps = {
  value: string;
  isValid?: boolean;
  equation?: EquationElement;
};

const Preview = forwardRef<HTMLDivElement, PreviewProps>(({ value, isValid, equation }, ref) => {
  const [mml, setMml] = useState<string | undefined>();

  useEffect(() => {
    if (!equation || value !== equation.tex) {
      setMml(MMLHelper.latexToMML(value));
    }

    if (equation && value === equation.tex) {
      setMml(equation.mathml ?? equation._equation);
    }
  }, [value, equation]);

  return (
    <div className={styles.preview}>
      <div className={styles.label}>
        <FormattedMessage id="PREVIEW" />:
      </div>
      <div className={styles.area}>
        {!value && (
          <div className={styles.empty}>
            <FormattedMessage id="EQUATION_PREVIEW" />
          </div>
        )}
        {isValid && <MathML mml={mml} tooltip={equation?.label} testId={'preview-equation'} />}

        {value && !isValid && (
          <div className={styles.invalid}>
            <Icon icon="Warning" size={32} />
            <FormattedMessage id="INVALID_EQUATION" />
          </div>
        )}
      </div>
    </div>
  );
});

export default Preview;
