import { Tooltip } from 'dodoc-design-system';
import MathComponent from './MathComponent/MathComponent';

type MathMLProps = {
  testId: string;
  tooltip?: string;
  mml?: string;
};

const MathML = ({ mml, tooltip, testId }: MathMLProps) => {
  if (tooltip) {
    return (
      <Tooltip content={tooltip} testId={`${testId}-tooltip`}>
        <div>
          <MathComponent mml={mml} />
        </div>
      </Tooltip>
    );
  }
  return <MathComponent mml={mml} />;
};

export default MathML;
