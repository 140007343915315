import { createContext, useContext } from 'react';
import { Structure, Symbol } from './ToolbarElements';

const EquationsContext = createContext<{
  onElementInsertion: (element: Symbol['elements'][number] | Structure['elements'][number]) => void;
  setIsValid: (value: boolean) => void;
}>({
  onElementInsertion: () => {},
  setIsValid: () => {},
});

export const useEquationsContext = () => {
  return useContext(EquationsContext);
};

export default EquationsContext;
